import { render, staticRenderFns } from "./ScrollNavigator.vue?vue&type=template&id=1d24f5d0&scoped=true&"
import script from "./ScrollNavigator.vue?vue&type=script&lang=js&"
export * from "./ScrollNavigator.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d24f5d0",
  null
  
)


    import installComponents from "!/Users/jurajpetras/dev/pmat/web/frontend/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BListGroupItem, BListGroup} from 'bootstrap-vue'
    installComponents(component, {BListGroupItem, BListGroup})
    


    import installDirectives from "!/Users/jurajpetras/dev/pmat/web/frontend/node_modules/bootstrap-vue-loader/lib/runtime/installDirectives.js"
    import {VBScrollspy} from 'bootstrap-vue'
    installDirectives(component, {'b-scrollspy': VBScrollspy})
    

export default component.exports