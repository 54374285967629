<template>
  <b-modal
    id="modal-scrollable"
    v-model="modalVisible"
    size="lg"
    scrollable
    title="Nahrať riešenie!"
    cancel-title="Zrušiť"
    cancel-variant="info"
    ok-title="Nahrať"
    ok-variant="success"
    @hidden="$emit('removedAllFiles')"
    @ok="handleOk"
  >
    <DropZone
      :enabled="true"
      title="Sem potiahni súbory!"
      :accept="constants.acceptedSubmitFormats"
      @dropped="uploadFiles"
      @wrongFileTypes="wrongFileTypes"
    >
      <p>
        Riešenie môžeš odovzdať ako viacero obrázkov aj PDF-iek.
        Pred tým než ich odovzáš, zoraď ich šipkami do správneho poradia
        a pootáčaj obrázky ak sú odfotené bokom alebo hore nohami.
      </p>
      <submit-file-entry
        v-for="(obj, index) in fileObjs"
        :key="obj.uuid"
        :file="obj.file"
        :index="index"
        :angle="obj.angle"
        @moveUp="moveUp(index)"
        @moveDown="moveDown(index)"
        @remove="remove(obj.uuid)"
        @rotateImage="rotateImage(index)"
      />
      <div class="row justify-content-center align-items-center">
        <h2 class="mr-2">
          <InfoTooltip
            :tooltip="'Akceptujeme riešenia vo forme viacerých obrázkov alebo PDF-iek!'
              + ' Ak nahráš nové riešenie, staré sa prepíše.'"
          />
        </h2>
        <b-form-file
          ref="fileInput"
          class="file-input mx-1 my-5"
          :accept="constants.acceptedSubmitFormats.join(', ')"
          placeholder="Tu nahraj ďaľšie súbory..."
          drop-placeholder="Nahrať..."
          browse-text="Vybrať"
          style="text-align: left"
          multiple
          @input="uploadFiles"
        />
      </div>
    </DropZone>
  </b-modal>
</template>

<script>
import {concatTransformationMatrix, degreesToRadians,
        PDFDocument, popGraphicsState, pushGraphicsState } from 'pdf-lib'
import {InfoTooltip} from 'frontend-common'
import {v4} from 'uuid'
import constants from '@/constants'
import DropZone from './utils/DropZone.vue'
import SubmitFileEntry from './SubmitFileEntry.vue'

const sin = (val) => Math.sin(degreesToRadians(val))
const cos = (val) => Math.cos(degreesToRadians(val))

export default {
  name: 'SubmitUploadModal',
  components: {
    SubmitFileEntry,
    InfoTooltip,
    DropZone,
  },
  props: {
    files: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      modalVisible: false,
      fileObjs: [],
      constants,
    }
  },
  watch: {
    files: {
      handler: function(value) {
        this.modalVisible = value.length > 0
        this.fileObjs = this.files.map(f => ({file: f, uuid: v4(), angle: 0}))
      },
      immediate: true,
    },
  },
  methods: {
    moveUp(index) {
      if (index === 0 || this.fileObjs.length <= 1) return
      const temp = this.fileObjs[index]
      this.$set(this.fileObjs, index, this.fileObjs[index - 1])
      this.$set(this.fileObjs, index - 1, temp)
    },
    moveDown(index) {
      if (index === this.fileObjs.length - 1 || this.fileObjs.length <= 1) return
      const temp = this.fileObjs[index]
      this.$set(this.fileObjs, index, this.fileObjs[index + 1])
      this.$set(this.fileObjs, index + 1, temp)
    },
    uploadFiles(files) {
      for (const file of files) {
        if (!constants.acceptedSubmitFormats.includes(file.type)) {
          this.wrongFileTypes()
          continue
        }

        this.fileObjs = [...this.fileObjs, {
          file, uuid: v4(), angle: 0,
        }]
      }
      this.$refs.fileInput.reset()
    },
    wrongFileTypes() {
      this.$root.dangerToast(
        'Niektoré súbory neboli správneho formátu! Akceptujeme len PDF, JPG a PNG!',
      )
    },
    remove(uuid) {
      this.fileObjs = this.fileObjs.filter(obj => obj.uuid !== uuid)
      if (this.fileObjs.length === 0) this.$emit('removedAllFiles')
    },
    rotateImage(index) {
      const newAngle = (this.fileObjs[index].angle + 90) % 360
      this.$set(this.fileObjs, index, {...this.fileObjs[index], angle: newAngle})
    },
    async handleOk(event) {
      event.preventDefault()

      const pdfDocument = await this.merge()
      if (pdfDocument === null) return

      const data = new Uint8Array(pdfDocument)
      const encoded = new Blob([data], {type: constants.mimeTypes.PDF})

      if (encoded.size < 600) {
        this.$root.dangerToast(
          'Pri nahrávaní súborov nastala chyba. Pravdepodobne súbory nie sú správneho typu. ' +
            'Akceptujeme len PDF, JPG a PNG',
        )
        return
      }

      this.$emit('uploadFile', encoded)
      this.$emit('removedAllFiles')
    },
    async merge() {
      const doc = await PDFDocument.create()

      for (const obj of this.fileObjs) {
        switch (obj.file.type) {
        case constants.mimeTypes.PNG:
          await this.addPNG(doc, obj)
          break
        case constants.mimeTypes.JPG:
          await this.addJPG(doc, obj)
          break
        case constants.mimeTypes.PDF:
          await this.addPDF(doc, obj.file)
          break
        default:
          this.wrongFileTypes()
          return null
        }
      }

      return await doc.save()
    },
    async addPNG(doc, obj) {
      const pngImage = await doc.embedPng(await this.getBytes(obj.file))
      await this.drawImage(doc, pngImage, obj.angle)
    },
    async addJPG(doc, obj) {
      const jpgImage = await doc.embedJpg(await this.getBytes(obj.file))
      await this.drawImage(doc, jpgImage, obj.angle)
    },
    async drawImage(doc, image, angle) {
      // CSS rotates opposite to the mathematical standar so we need to flip the angle
      angle = -angle
      const page = doc.addPage()
      const pageBorder = 80
      const w = page.getWidth() - pageBorder
      const h = page.getHeight() - pageBorder
      const dims = image.scaleToFit(
        Math.abs(w*cos(angle)+h*sin(angle)),
        Math.abs(w*sin(angle) + h*cos(angle)),
      )
      const originX = page.getWidth()/2, originY = page.getHeight()/2
      page.pushOperators(
        pushGraphicsState(),
        concatTransformationMatrix(
          1,
          0,
          0,
          1,
          originX,
          originY,
        ),
        concatTransformationMatrix(
          cos(angle),
          sin(angle),
          -sin(angle),
          cos(angle),
          0,
          0,
        ),
        concatTransformationMatrix(
          1,
          0,
          0,
          1,
          -1 * originX,
          -1 * originY,
        ),
      )
      page.drawImage(image, {
        x: originX - dims.width / 2,
        y: originY - dims.height / 2,
        width: dims.width,
        height: dims.height,
      })
      page.pushOperators(
        popGraphicsState(),
      )
    },
    async addPDF(doc, file) {
      const pdf = await PDFDocument.load(await this.getBytes(file))
      const pages = await doc.copyPages(pdf, pdf.getPageIndices())
      for (const page of pages) {
        doc.addPage(page)
      }
    },
    async getBytes(file) {
      return new Uint8Array(await this.readFile(file))
    },
    readFile(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()

        reader.addEventListener('loadend', e => resolve(e.target.result))
        reader.addEventListener('error', reject)

        reader.readAsArrayBuffer(file)
      })
    },
  },
}
</script>

<style scoped>
.file-input {
  max-width: 20em;
}
</style>
