<template>
  <b-row>
    <b-col
      cols="2"
      sm="1"
    >
      <h2>
        <InfoTooltip
          :tooltip="'Ak chceš svoje riešenie zmeniť, jednoducho odovzdaj riešenie znovu.'"
        />
      </h2>
    </b-col>
    <b-col
      v-if="!isOrganizer"
      class="ml-2"
    >
      <b-overlay
        :show="ongoingSubmit"
        spinner-small
      >
        <b-form-file
          ref="file-input"
          :accept="constants.acceptedSubmitFormats.join(', ')"
          placeholder="Potiahni sem súbor..."
          drop-placeholder="Nahrať..."
          browse-text="Vybrať"
          style="text-align: left"
          multiple
          @input="uploadFiles"
        />
      </b-overlay>
    </b-col>
    <b-col
      v-else
      class="ml-3 py-2"
    >
      <font-awesome-icon
        :icon="['fas', 'exclamation']"
        class="mr-1"
      /> Vedúci nemôže nahrávať riešenia.
    </b-col>
    <SubmitUploadModal
      :files="files"
      @removedAllFiles="files = []"
      @uploadFile="uploadFile"
    />
  </b-row>
</template>

<script>
import { apiSubmits, InfoTooltip } from 'frontend-common'
import constants from '@/constants'
import SubmitUploadModal from './SubmitUploadModal.vue'

export default {
  name: 'ProblemSubmitUpload',
  components: {
    InfoTooltip,
    SubmitUploadModal,
  },
  mixins: [
    apiSubmits,
  ],
  props: {
    problemId: {
      type: Number,
      required: true,
    },
    problemAllowedForMe: {
      type: Boolean,
      required: true,
    },
    previousSubmit: {
      type: Boolean,
      required: true,
    },
    analyticsProblemName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      constants,
      files: [],
      ongoingSubmit: false,
    }
  },
  computed: {
    isOrganizer() {
      return this.$root.stateLoaded && this.$root.state.user &&
        this.$root.state.user.organizer !== null
    },
  },
  watch: {
    files: {
      handler: function (newVal, oldVal) {
        const opened = (val) => val?.length > 0
        if (opened(newVal) === opened(oldVal)) return
        this.$emit('modalToggled', opened(newVal))

        if (opened(newVal))
          this.$refs['file-input']?.reset()
      },
      immediate: true,
    },
  },
  created() {
    this.$emit('hookUploadFiles', this.uploadFiles)
  },
  methods: {
    overwriteSubmitWarning() {
      const h = this.$createElement
      return h('div', [
        h('p', ['Nahratím nového riešenia sa odstráni predchádzajúce odovzdané riešenie!']),
      ])
    },
    async uploadFiles(files) {
      if (!files || files.length === 0) return

      // The form file component doesn't ensure file type consistency. We need to manually
      // check every file and discart it if it isn't any of the supported upload types
      for (const file of files) {
        if (constants.acceptedSubmitFormats.includes(file.type)) continue
        this.$root.dangerToast(
          'Niektoré súbory neboli správneho formátu! Akceptujeme len PDF, JPG a PNG!',
        )
        this.$refs['file-input'].reset()
        return
      }

      if (this.$root.state.outdatedProfile) {
        await this.$root.dangerModalOk(
          'Neaktuálne údaje',
          'Počas tohto školského roku si si ešte neskontroloval/-a aktuálnosť osobných údajov.' +
            ' Budeš presmerovaný/-á na stránku, kde si ich môžeš skontrolovať.',
        )
        await this.$router.push({name: 'profile-change'})
        return
      }

      if(!this.problemAllowedForMe) {
        const shouldUpload = await this.$root.warningModalConfirm(
          'Naozaj nahrať?',
          'Odovzdávaš riešenie úlohy ktorá nie je pre tvoju kategóriu. Úlohu ti opravíme ale body' +
            ' za ňu nedostaneš. Skontroluj či nahrávaš riešenie k správnej úlohe.',
        )
        if (!shouldUpload) {
          this.$root.infoToast('Nahrávanie riešenia zrušené.', 6000)
          this.$refs['file-input'].reset()
          return
        }
      }
      if (this.previousSubmit) {
        const shouldUpload = await this.$root.warningModalConfirm(
          'Naozaj nahrať?',
          this.overwriteSubmitWarning(),
        )
        if (!shouldUpload) {
          this.$root.infoToast('Nahrávanie riešenia zrušené.', 6000)
          this.$refs['file-input'].reset()
          return
        }
      }
      // Set the files property which trigger the uplaod modal to open
      this.files = files
    },
    async uploadFile(blob) {
      this.ongoingSubmit = true
      this.apiPostSubmit(this.problemId, blob).then(() => {
        this.$emit('submitted')
        this.$root.successToast('Riešenie úspešne odovzdané!')
        this.$root.silentlyLoadStatus()
      }).catch(() => {
        // TODO proper error discovery and handling
        this.$root.dangerToast(
          'Riešenie sa nepodarilo nahrať. Skontroluj, či súbory majú pod 10 MB.',
          8000,
        )
      }).finally(() => {
        this.$refs['file-input'].reset()
        this.ongoingSubmit = false
      })
    },
  },
}
</script>

<style scoped>

</style>
