<template>
  <div>
    <b-jumbotron
      bg-variant="primary"
      text-variant="white"
      class="px-0 py-4"
    >
      <b-container>
        <b-row
          align-v="center"
          no-gutters
        >
          <b-col lg="7">
            <h2>
              {{ fullRoundName }}
            </h2>
            <slot />
          </b-col>
          <b-col lg="5">
            <TimeProgress
              :start-time="new Date(round.start_time)"
              :end-time="new Date(round.end_time)"
              :now="now"
              class="mt-3"
            />
          </b-col>
        </b-row>
      </b-container>
    </b-jumbotron>
    <b-container>
      <p>
        <span
          v-if="!booklet.visible"
          class="text-danger mr-3"
        >
          <font-awesome-icon
            :icon="['fas', 'exclamation']"
            class="mr-1"
          />
          Tieto zadania (booklet) nie sú zverejnené pre riešiteľov.
        </span>
        <a
          :disabled="!booklet.printable_version"
          :href="booklet.printable_version"
          target="_blank"
          @click="trackOpenPDFBooklet(fullRoundName)"
        >
          <span
            v-b-popover.hover.top="booklet.printable_version ?
              '' : 'Tlačenú verziu úloh ešte stále pripravujeme.'"
          >
            <font-awesome-icon :icon="['fas', 'print']" /> Stiahnuť zadania vo formáte PDF
          </span>
        </a>
      </p>
      <p>
        <small>
          Každé riešenie môžeš odovzdať ako skupinu obrázkov a PDF-iek,
          no súbory pre jedno riešenie musia mať menej ako 10MB.
        </small>
      </p>
      <p v-if="highlightProblems">
        <small>
          Úlohy označené <FavoriteStar /> sú určené pre tvoju kategóriu.
        </small>
      </p>
      <div
        v-for="block of booklet.blocks"
        :key="block.position"
        class="mt-3"
      >
        <AdvancedMarkdown
          v-if="block.block_type === 'text'"
          :source="block.text"
        />
        <ProblemDetail
          v-else-if="block.block_type === 'problem'"
          :id="'uloha-' + block.problem.number"
          v-model="modalVisible"
          :class="{'print-no-break': block.problem.statement.length < 1500}"
          :problem="problems.find(problem => problem.id === block.problem.id)"
          :allow-upload="!roundEnded"
          :analytics-round-name="fullRoundName"
          :highlight-problems="highlightProblems"
          :in-submit="submitsByProblem[block.problem.id] || null"
          :round="round"
        />
      </div>
    </b-container>
  </div>
</template>

<script>
import { AdvancedMarkdown, analytics, apiSubmits, TimeProgress } from 'frontend-common'
import constants from '@/constants'
import FavoriteStar from '@/components/utils/FavoriteStar.vue'
import ProblemDetail from '@/components/ProblemDetail.vue'

export default {
  name: 'RoundDetail',
  components: {
    FavoriteStar,
    ProblemDetail,
    TimeProgress,
    AdvancedMarkdown,
  },
  mixins: [
    analytics,
    apiSubmits,
  ],
  props: {
    round: {
      type: Object,
      required: true,
    },
    booklet: {
      type: Object,
      required: true,
    },
    problems: {
      type: Array,
      required: true,
    },
    fullRoundName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      now: new Date(),
      interval: null,
      submitsByProblem: {},
      // Indicates if upload modal is open to disable all dropzones
      modalVisible: false,
    }
  },
  computed: {
    roundEnded() {
      return new Date(this.round.end_time) < this.now
    },
    highlightProblems() {
      return this.problems.some(problem => problem.recommendedForMe)
    },
  },
  mounted() {
    this.interval = setInterval(() => this.now = new Date(), constants.autoReloadIntervals.short)

    // Only load submits if user is logged in and he/she is not an organizer
    if (this.$root.isAuthenticated && this.$root.stateLoaded && !this.$root.state.user.organizer) {
      this.apiSubmitRoundLatest(this.round.id).
        then(data => {
          const newSubmits = {}
          for (const submit of data) {
            newSubmits[submit.problem] = submit
          }
          this.submitsByProblem = newSubmits
        })
    }
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
}
</script>

<style scoped>

</style>
