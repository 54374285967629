<template>
  <b-row no-gutters>
    <b-col>
      <b-container class="mt-5 mb-3">
        <h1>Úlohy a riešenia</h1>
      </b-container>
      <LoadingSpinner
        :is-loaded="!!selectedRound && bookletLoaded"
      >
        <RoundDetail
          :round="selectedRound"
          :booklet="booklet"
          :problems="problems"
          :full-round-name="selectedContestRoundName"
          class="mt-3"
        >
          <LoadingSpinner :is-loaded="contestsLoaded && roundsLoaded">
            <RoundSelectionDropdown
              :contests="contests"
              :rounds="rounds"
              view-name="round"
            />
          </LoadingSpinner>
        </RoundDetail>
      </LoadingSpinner>
    </b-col>
    <b-col
      xl="3"
      class="sticky-below-navbar problem-nav d-none d-xl-block p-2"
    >
      <ScrollNavigator
        v-if="selectedRound && bookletLoaded"
        :items="scrollNavigatorItems"
        :scroll-config="scrollConfig"
      />
    </b-col>
  </b-row>
</template>

<script>
import { apiBooklets, apiContests, apiRounds, LoadingSpinner,
         RoundSelectionDropdown, utils } from 'frontend-common'
import RoundDetail from '@/components/RoundDetail.vue'
import ScrollNavigator from '@/components/utils/ScrollNavigator.vue'
import VueScrollTo from 'vue-scrollto'

export default {
  name: 'Contests',
  components: {
    LoadingSpinner,
    RoundDetail,
    RoundSelectionDropdown,
    ScrollNavigator,
  },
  mixins: [
    apiBooklets,
    apiContests,
    apiRounds,
  ],
  props: {
    roundId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      scrollConfig: {
        duration: 250,
        offset: -150,
        delay: 1000,
      },
      contests: [],
      contestsLoaded: false,
      rounds: [],
      roundsLoaded: false,
      selectedRound: null,
      booklet: {},
      bookletLoaded: false,
    }
  },
  computed: {
    selectedContestRoundName() {
      if (!this.selectedRound || !this.selectedRound.contest) return ''
      return utils.fullRoundName(this.selectedRound.contest.name, this.selectedRound.name)
    },
    problems() {
      if (!this.booklet.blocks) {
        return []
      }
      const competition = this.$root.state.competition
      const currentContest = competition.current_round ? competition.current_round.contest.id : null
      return this.booklet.blocks.
        filter(block => block.block_type === 'problem').
        map(block => block.problem).
        map(problem => ({
          ...problem,
          recommendedForMe: this.selectedRound.contest.id === currentContest &&
            this.$root.isProblemRecommendedForMe(problem),
          allowedForMe: this.selectedRound.contest.id === currentContest &&
            this.$root.isProblemAllowedForMe(problem),
        }))
    },
    scrollNavigatorItems() {
      return this.problems.map(problem => ({
        scrollId: `#uloha-${problem.number}`,
        text: `${problem.number}. ${problem.name}`,
        highlighted: problem.recommendedForMe,
      }))
    },
  },
  watch: {
    $route(to, from) {
      // if we didn't check this, every url hash change would reload the round
      if (String(to.params.roundId) !== String(from.params.roundId)) {
        this.loadRound()
      }
    },
    '$root.stateLoaded': 'loadContests',
  },
  mounted() {
    this.loadContests()
  },
  methods: {
    loadContests() {
      if (!this.$root.stateLoaded) return

      this.contestsLoaded = false
      this.roundsLoaded = false

      this.apiContests().
        then(response => this.contests = response.reverse()).
        finally(() => this.contestsLoaded = true)

      this.apiRounds().
        then(response => {
          this.rounds = response
          this.loadRound()
        }).
        finally(() => this.roundsLoaded = true)
    },
    loadRound() {
      if (this.rounds.length === 0) return
      if (this.roundId === null) {
        if (!this.$root.stateLoaded) return
        this.$router.replace({
          name: 'round',
          params: { roundId: this.$root.state.competition.current_round ?
            this.$root.state.competition.current_round.id : this.rounds[0].id },
        })
        return
      }

      this.selectedRound = this.rounds.find(round => round.id === this.roundId)
      this.bookletLoaded = false
      // TODO add support for multi-booklet rounds
      this.apiBooklet(this.selectedRound.booklet_set[0]).
        then(response => this.booklet = response).
        finally(() => {
          this.bookletLoaded = true
          if (this.$route.hash) {
            setTimeout(
              () => VueScrollTo.scrollTo(this.$route.hash, this.scrollConfig),
              this.scrollConfig.delay,
            )
          }
        })
    },
  },
}
</script>

<style scoped>
.problem-nav {
  font-size: 90%;
}
</style>
